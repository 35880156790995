<section id="Kin" class="sb-info">
    <div class="info-container">
        <div class="info">
            <div class="info-content" style="margin-right: 2em; text-align: left">
                <img class="mobile-image-kin" src="assets/img/kin-logo-preview.png">
                <br><br>
                <p class="kinText">
                    Kin is money for the digital world. It is the most widely spent
                    cryptocurrency within its large ecosystem of apps and users.<br><br>
                    Kin’s focus on delivering the best user experience has been the
                    force behind many blockchain innovations.<br><br>
                    The migration to Solana is the latest evolution to become the
                    fastest and most scalable money for the digital age.<br><br>
                </p>
            </div>
            <div class="info-content" style="text-align: center !important; display: grid; justify-content: center;">
                <div class="kinTextPurple">
                         <div class="flex">38</div>
                         <div class="flex">Active Apps</div><br>
                </div>
                <div class="kinTextPurple">
                         <div class="flex">2.4M</div>
                         <div class="flex">Monthly Active Spenders</div>
                </div>
                <div class="kinTextGrey" style="padding-top: 2em;">
                            <div class="flex">Total Unique Spenders</div>
                            <div class="flex">Total Unique Earners</div>
                </div>
                <div class="kinTextGrey" style="padding-top: 2em;">
                            <div class="flex">22.1 Million</div>
                            <div class="flex">41.1 Million</div>
                </div>
                <div class="statsLink">
                    <a target="_blank" href="https://www.kin.org/stats">See latest stats</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="Solana" class="sb-info" style="background-color: black;">
    <div class="info-container">
        <div class="info">
            <div class="info-content">
            <img class="mobile-image-network" src="assets/img/kinappsnetwork.png" style="text-align: left !important;">
            </div>
            <div class="info-content " style="display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;">
                <img class="mobile-image-solana" src="assets/img/solanalogo.png" style="margin-left: -50%;"><br>
                <p class="solanaText" >
                    The Solana blockchain is fast and highly scalable, and allows
                    Kin to continue growing by supporting its large network of
                    apps and millions of users.<br><br>
                    Solana allows Kin app developers to provide a highly user
                    friendly experience with almost zero transaction wait times.
                </p>
            </div>
        </div>
    </div>
</section>

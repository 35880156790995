<section id="Benefits" class="sbpro-page-section" style="padding-top: 4em;">
  <div class="features-container">
    <div class="feature">
      <div>
      <img src="assets/img/electricity.png" style="width: 110px; height: 110px">
    </div>
      <h2>Blazing Speeds</h2>
      <p>Eliminate wait times<br>
        50,000 transactions per second<br>
        400ms block times</p>
    </div>
    <div class="feature">
      <div>
        <img src="assets/img/lock.png" style="width: 135px; height: 123px; object-fit: cover;">
      </div>      
      <h2>Highly Secure</h2>
      <p>Iron-clad immutability for global scale<br>
        178 global validators</p>
    </div>
    <div class="feature">
      <div>
        <img src="assets/img/tagLink.png" style="width: 110px; height: 110px">
      </div>
      <h2>Scalable</h2>
      <p>Webscale for global adoption<br>
        Smart contract support</p>
    </div>
  </div>
  <div class="learn-more-container">
    <a target="_blank" href="https://solana.com/" ><button
    class="learn-more-btn">Learn more</button></a>
  </div>
</section>

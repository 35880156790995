<section id="Apps" class="sbpro-features" style="padding-top: 4em;">
  <h2 class="apps-title">Apps Supporting Kin on Solana</h2>
  <div class="features-container" id="features-container">
    <div class="feature" *ngFor="let integration of integrations | slice:0:max_integrations; let i=index">
      <span class="feature-bg"><img src='{{ "assets/img/integrations/" + integration.background_image }}' /></span>
      <h3 class="feature-title">{{ integration.title }}</h3>
      <p class="feature-info">
        <read-more [content]="integration.info"></read-more>
      </p>
    </div>
  </div>
  <a class="all-integrations" [class.hide]="integrations.length <= 6" (click)="allIntegrations($event)">{{ read_more == true ? "Read more" : "Read less" }}</a>
</section>
import { Component, OnInit } from '@angular/core';
import { ScullyRoutesService } from '@scullyio/ng-lib';
import { faFeather } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private scully: ScullyRoutesService) {}

  title = 'SolanaSwapWebsite';

  faFeather = faFeather;

  ngOnInit() {
  }
}

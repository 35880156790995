<section class="about">
    <div class="qna-container">
      <h1>Swap FAQ</h1>

        <ul>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>What is Kin 1, Kin 2, Kin 3 and Kin 4?</h2>
              <p>
                Kin 1: First generation of Kin running on Ethereum (ERC20)<br>
                Kin 2: Second generation of Kin running on Stellar (non-mainnet)<br>
                Kin 3: Third generation of Kin running on Stellar (non-mainnet), where Kin is the base asset<br>
                Kin 4: Fourth generation of Kin running on Solana (mainnet)
              </p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>Why is Kin migrating to the Solana blockchain?</h2>
              <p>The Kin ecosystem’s growth has exceeded its own blockchain’s capability to support users by providing a user friendly experience. To support Kin’s continued growth and provide very low user wait times and increased scalability, Solana has been identified to be the best blockchain solution.</p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>When will the migration to the Solana blockchain occur?</h2>
              <p>December 8, 2020</p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>What should I do to prepare for the migration?</h2>
              <p>Verify that the app you use will support the Solana migration. Migration to the Solana Blockchain will happen automatically. If your Kin app or wallet app is supporting the swap, you do not have to take any action. After migration, your Kin balance will be available for use on the new blockchain.</p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>Will I be able to access my Kin after migration?</h2>
              <p>Yes. Since Solana uses the same elliptic curve as the Kin blockchain, your public wallet address and private keys will remain the same, and therefore your ability to access your funds will remain unaffected.
                To ensure that your access to Kin after migration is successful, the Kin Foundation recommends utilizing the supported exchanges and wallets. Note that your Kin may be inaccessible if left on non-supported exchanges or wallets until that particular exchange or wallet decides to continue supporting Kin at a future date.</p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>Which wallets will be supported?</h2>
              <p>Ledger and   
                <a class="links" target="_blank" href="https://www.mykinwallet.org/">MyKinWallet.org</a>
                will be supported. All other wallets have not been reported to support the swap process at this time.
                <br><br>  
                <a class="links" target="_blank" href="https://www.reddit.com/r/KinFoundation/comments/jwi6vl/easy_atomic_migration_option_extract_private_key/">Atomic Wallet Migration Option</a>
                <br>
                <a class="links" target="_blank" href="https://www.reddit.com/r/KinFoundation/comments/bylk0r/creating_a_paper_wallet_for_storing_your_kin/">Paper Wallet Migration Option</a>
              </p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>Which exchanges will be supported?</h2>
              <p>Bithumb Global, Gate.io, Cointiger, Mercatox, HitBTC. New exchange listing to be announced at a later date post the Dec 8th migration event.</p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>What should I do if I still hold Kin 1 (ERC20)?</h2>
              <p>Details on swap via Ethereum-Solana Wormhole to be announced.</p>
            </li>
            <li>
              <input type="checkbox" checked>
              <i></i>
              <h2>How should I prepare as a Kin app developer?</h2>
              <p>
                Developer should upgrade their SDKs to the latest version.<br>
                <a class="links" target="_blank" href="https://docs.kin.org/android/sdk">Android</a><br>
                <a class="links" target="_blank" href="https://docs.kin.org/ios/sdk">iOS</a><br>
                <a class="links"  target="_blank" href="https://docs.kin.org/go/sdk">Go</a><br>
                <a class="links" target="_blank" href="https://docs.kin.org/python/sdk">Python</a><br>
                <a class="links" target="_blank" href="https://docs.kin.org/nodejs/sdk">Node</a>
              </p>
            </li>
        </ul>
    </div>
</section>
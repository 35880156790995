import { Component, OnInit } from '@angular/core';
import { faHistory, faChartLine, faBolt, faCode, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sbpro-page-section',
  templateUrl: './sbpro-page-section.component.html',
  styleUrls: ['./sbpro-page-section.component.scss']
})
export class SbproPageSectionComponent implements OnInit {

  faHistory = faHistory;
  faChartLine = faChartLine;
  faBolt = faBolt;
  faCode = faCode;
  faUnlockAlt = faUnlockAlt;

  constructor() { }

  ngOnInit(): void {
  }

}

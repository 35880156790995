import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sb-customizer',
  templateUrl: './sb-customizer.component.html',
  styleUrls: ['./sb-customizer.component.scss']
})
export class SbCustomizerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbpro-features',
  templateUrl: './sbpro-features.component.html',
  styleUrls: ['./sbpro-features.component.scss']
})
export class SbproFeaturesComponent implements OnInit {

  constructor() { }

  // tslint:disable-next-line: variable-name
  max_integrations = 6;
  // tslint:disable-next-line: variable-name
  read_more = true;

  ngOnInit(): void {
  }

  integrations = [
    {
      "background_image": "kinny.webp",
      "title": "Kinny",
      "info": `Send and receive Kin over social media and complete activities to earn Kin.`
    },
    {
      "background_image": "AgentsCoveLogo.webp",
      "title": "AgentsCove",
      "info": `Hub connecting you to House Owners, Property Agents and Students in search of a roomie.`
    },
    {
      "background_image": "KinFitLogo.webp",
      "title": "KinFit",
      "info": `KinFit is the app where you can earn real cryptocurrency, just by going out and being active. We keep track of the number of steps you take every day, and you earn Kin based on those steps. Walk and get paid!`
    },
    {
      "background_image": "PauseForLogo.webp",
      "title": "Pause For",
      "info": `Give to your favorite charities by taking a break from your phone! Pause For is a productivity app, built to help users focus on their tasks while earning digital currency for their chosen charities.`
    },
    {
      "background_image": "PopInLogo.webp",
      "title": "Pop.in",
      "info": `Pop.in is all about playing live games with people, just like going to a party at a friend’s house. Whether it’s a casual game of cards, or a bigger event where you can win prizes, pop.in is the place to be together when you're not, make new friends, and play awesome games.`
    },
    {
      "background_image": "madlipz.webp",
      "title": "MadLipz",
      "info": `MadLipz lets you make hilarious bite-sized video parodies (Lipz) and share them with everyone!`
    },
    {
      "background_image": "rave.webp",
      "title": "Rave",
      "info": `Seamlessly Mash up any 2 songs with the World's first AI DJ.`
    },
    {
      "background_image": "p365.png",
      "title": "Perfect365",
      "info": `Our augmented reality beauty platform allows millions of millennials and Gen Zers to try makeup and hair from
      hundreds of brands digitally.`
    },
    {
      "background_image": "peerbet_icon.png",
      "title": "PeerBet",
      "info": `Free social betting app that lets you place bets against your friends and other players.`
    }
    // {
    //   "background_image": "",
    //   "title": "Kin Ads",
    //   "info": ``
    // },
    // {
    //   "background_image": "kik.webp",
    //   "title": "Kik",
    //   "info": `Connecting the world through chat.`
    // },

  ]

  toggleShow(event){
    // tslint:disable-next-line: variable-name
    const show_more_link = event.target;
    // tslint:disable-next-line: variable-name
    const more_text = event.target.previousElementSibling;
    const dots = event.target.previousElementSibling.previousElementSibling;
    if(more_text.style.display === 'block'){
      more_text.style.display = 'none';
      show_more_link.innerHTML = 'Read more';
      dots.style.display = 'inline-block';
    } else {
      more_text.style.display = 'block';
      dots.style.display = 'none';
      show_more_link.innerHTML = 'Read less';
    }
  }

  allIntegrations(event){
    if(this.read_more){
      this.max_integrations = 1000;
    } else {
      this.max_integrations = 6;
    }
    this.read_more = !this.read_more;
  }

}

import { Component, Input, ElementRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'read-more',
  template: `
      <div [innerHTML]="content" [class.collapsed]="isCollapsed"></div>
      <a (click)="isCollapsed = !isCollapsed" *ngIf="isCollapsed" class="read-more" [style.color]="linkColor">{{ isCollapsed ? 'Show more' : 'Show less' }}</a>
  `,
  styles: [`
      div.collapsed {
          height: 45px;
          overflow: hidden;
      }
      .read-more {
        margin-top: 1em;
        text-decoration: none;

        color: #0061f2;
      }
      .read-more:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      .show {
        display: block;
      }
  `]
})
export class ReadMoreComponent implements AfterViewInit {

    //the text that need to be put in the container
    @Input() content: string;

    //update's appColor to style "read more" link
    @Input() linkColor: string;

    //updateId to redirect to full-page
    @Input() updateId: number;

    //maximum height of the container
    @Input() maxHeight: number = 45;

    //set these to false to get the height of the expended container 
    public isCollapsed: boolean = false;
    public isCollapsable: boolean = false;

    constructor(private elementRef: ElementRef, private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        let currentHeight = this.elementRef.nativeElement.getElementsByTagName('div')[0].offsetHeight;
       //collapsable only if the contents make container exceed the max height
        if (currentHeight > this.maxHeight) {
            this.isCollapsed = true;
            this.isCollapsable = true;
            this.cdr.detectChanges();
        }
    }
}
import { Component, OnInit } from '@angular/core';
import { faInfo, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sb-info',
  templateUrl: './sb-info.component.html',
  styleUrls: ['./sb-info.component.scss']
})
export class SbInfoComponent implements OnInit {

  constructor() { }

  faInfo = faInfo;
  faSwitch = faExchangeAlt;

  ngOnInit(): void {
  }

}

import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-sb-top-nav',
  templateUrl: './sb-top-nav.component.html',
  styleUrls: ['./sb-top-nav.component.scss'],
})
export class SbTopNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  dropdownStatus = false;
  activeDropdown = "";
  navToggleStatus = false;

  dropdownStatusToggle(){
    this.dropdownStatus = !this.dropdownStatus;
  }

  dropdownToggle(event){
    const dropdown = event.target.nextElementSibling;
    if(this.dropdownStatus == false){
      dropdown.style.display = "block";
      this.dropdownStatusToggle();
    } else {
      dropdown.style.display = "none";
      this.dropdownStatusToggle();
    }
  }

  changeActiveDropdown(event){
    const id = event.target.id;
    this.activeDropdown = id;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event){
    if(event.target.className != "dropdown-trigger"){
      this.activeDropdown = "";
      this.dropdownStatusToggle();
    }
  }

  navToggle(){
    this.navToggleStatus = !this.navToggleStatus;
  }


}

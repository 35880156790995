import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScullyLibModule, ScullyContentModule } from '@scullyio/ng-lib';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SbFooterComponent } from './components/sb-footer/sb-footer.component';
import { SbproLayoutLandingComponent } from './components/sbpro-layout-landing/sbpro-layout-landing.component';
import { SbproMobileAppComponent } from './components/sbpro-mobile-app/sbpro-mobile-app.component';
import { SbproPageSectionComponent } from './components/sbpro-page-section/sbpro-page-section.component';
import { SbproBorderComponent } from './components/sbpro-border/sbpro-border.component';
import { SbCustomizerComponent } from './components/sb-customizer/sb-customizer.component';
import { SbTopNavComponent } from './components/sb-top-nav/sb-top-nav.component';
import { SbproPageHeaderComponent } from './components/sbpro-page-header/sbpro-page-header.component';
import { ScrollTopButtonComponent } from './components/scroll-top-button/scroll-top-button.component';
import { SbproFeaturesComponent } from './components/sbpro-features/sbpro-features.component';
import { SbInfoComponent } from './components/sb-info/sb-info.component';
import { FutureAppPageComponent } from './components/future-app-page/future-app-page.component';
import { HomepageComponent } from './components/homepage/homepage.component';
import { FaqComponent } from './components/faq/faq.component';
import { ReadMoreComponent } from './shared/components/read-more/read-more.component';

@NgModule({
  declarations: [
    AppComponent,
    SbFooterComponent,
    SbproLayoutLandingComponent,
    SbproMobileAppComponent,
    SbproPageSectionComponent,
    SbproBorderComponent,
    SbCustomizerComponent,
    SbTopNavComponent,
    SbproPageHeaderComponent,
    ScrollTopButtonComponent,
    SbproFeaturesComponent,
    SbInfoComponent,
    HomepageComponent,
    FutureAppPageComponent,
    FaqComponent,
    ReadMoreComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    ScullyLibModule,
    ScullyContentModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }

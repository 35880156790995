<footer class="sb-footer">
  <div class="container">
    <div class="lists">
      <div class="list">
        <h4 class="list-title">Jump To</h4>
        <ul>
          <li><a [routerLink]="['/']" fragment="Kin">Kin</a></li>
          <li><a [routerLink]="['/']" fragment="Solana">Why Solana</a></li>
          <li><a [routerLink]="['/']" fragment="Benefits">Benefits</a></li>
          <li><a [routerLink]="['/']" fragment="Apps">Supported Apps</a></li>
        </ul>
      </div>
      <div class="list">
        <section id="Resources">
        <h4 class="list-title">Resources</h4>
        <ul>
          <li>
            <a target="_blank" href="https://www.kin.org/">Kin</a>
          </li>
          <li>
            <a target="_blank" href="https://solana.com/">Solana</a>
          </li>
          <li>
            <a target="_blank" href="https://www.reddit.com/r/KinFoundation/">Kin Subreddit</a>
          </li>
        </ul>
      </section>
      </div>
      <div class="list">
        <h4 class="list-title">Swap FAQ</h4>
        <ul>
          <li><a [routerLink]="['faq']">About the Swap</a></li>
        </ul>
      </div>
      <!-- <div class="list">
        <h4 class="list-title">Legal</h4>
        <ul>
          <li><a href="#">Link 1</a></li>
          <li><a href="#">Link 2</a></li>
          <li><a href="#">Link 3</a></li>
        </ul>
      </div> -->
    </div>
    <hr>
    <div class="bottom-footer">
      <div>
        <h5><a target="_self" href="assets/documents/Privacy_Policy_SolanaSwap.pdf" download="Privacy_Policy_SolanaSwap.pdf">Privacy Policy</a></h5>
        <h5>Copyright &copy; Kinny 2020</h5>
      </div>
      <!-- <div>
        <a href="#">ToS</a>
        ·
        <a href="#">Privacy Policy</a>
      </div> -->
    </div>
  </div>
</footer>
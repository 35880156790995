<section class="sbpro-mobile-app">
  <div class="container">
    <div class="side text">
      <h3>This page was brought to you by the Kinny team.</h3>
      <h4>Get the app now!</h4>
      <p>Ready to get started? Download the app now.</p>
    </div>
    <div class="side links">
      <a href="#" class="download-link">
        <img src="assets/img/google-play-badge.svg" class="download-image">
      </a>
      <a href="#" class="download-link">
        <img src="assets/img/app-store-badge.svg" class="download-image">
      </a>
    </div>
  </div>
</section>
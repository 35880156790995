<header class="navigation">
  <div class="nav-container">
    <div class="brand">
      <a [routerLink]="['/']">Solana Swap</a>
    </div>
    <nav [ngClass]="navToggleStatus ? 'responsive' : ''">
      <div class="nav-mobile">
        <a id="nav-toggle" [ngClass]="navToggleStatus ? 'active' : ''" (click)="navToggle()"><span></span></a>
      </div>
      <ul class="nav-list" [style.display]="navToggleStatus ? 'block' : 'none'">
        <li>
          <a [routerLink]="['/']" fragment="Kin" (click)="navToggle()">Kin</a>
        </li>
        <li>
          <a [routerLink]="['/']" fragment="Solana" (click)="navToggle()">Why Solana</a>
        </li>
        <li>
          <a [routerLink]="['/']" fragment="Benefits" (click)="navToggle()">Benefits</a>
        </li>
        <li>
          <a [routerLink]="['/']" fragment="Apps" (click)="navToggle()">Supported Apps</a>
        </li>
        <li>
          <a style="cursor: pointer;" id="about-dropdown" class="dropdown-trigger" (click)="dropdownToggle($event); changeActiveDropdown($event)">Resources</a>
          <ul class="nav-dropdown" [style.display]="activeDropdown ? 'block' : 'none'">
            <li>
              <a target="_blank" href="https://www.kin.org/">Kin</a>
            </li>
            <li>
              <a target="_blank" href="https://solana.com/">Solana</a>
            </li>
            <li>
              <a target="_blank" href="https://www.reddit.com/r/KinFoundation/">Kin Subreddit</a>
            </li>
          </ul>
        </li>
        <li class="heading-button-container">
          <button [routerLink]="['faq']" (click)="navToggle()">FAQs</button>
        </li>
      </ul>
    </nav>
    <div class="heading-button-container">
      <button [routerLink]="['faq']">Swap FAQ</button>
    </div>
  </div>
</header>
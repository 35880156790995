<header class="sbpro-page-header">
  <div class="header-content">
    <div class="heading-text heading-item">
      <h1>Kin is Evolving. It’s Getting Faster.</h1>
      <br>
      <p>Don't get left behind! Learn about the migration to Solana.</p>
      <br><br><br><br>
      <p class="migrationText">Migration Date - December 8, 2020</p>
    </div>
    <div class="heading-image heading-item">
      <img class="mobile-image" src="assets/img/swapcoins.png">
    </div>
  </div>
</header>
